<template>
    <div class="">
        <div class="heading">{{heading}}</div>
            <div class="heading-description mt-3">
               {{ description }}
            </div>
    </div>
</template>
<script>
export default{
    props:{
        heading:String,
        description:String
    }
}
</script>
<style scoped>
 .heading {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 42px;
  
    color: #18191c;
  }
  .heading-description{
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 14px;
color: #73738D;
  }
</style>